body {
  margin: 0px !important;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("./Assets/fonts/Roboto-Bold.ttf");
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("./Assets/fonts/Roboto-Regular.ttf");
}

.Roboto-Bold {
  font-family: "Roboto-Bold" !important;
}

.Roboto-Regular {
  font-family: "Roboto-Regular" !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black !important;
  opacity: 1; /* Firefox */
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ant-input-affix-wrapper > input.ant-input:focus {
  box-shadow: 0 0 0 30px #c4c4c4 inset !important;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #c4c4c4 inset !important;
}

.ant-spin-dot-item {
  background-color: red;
}